<template>
  <div>
    <section class="page-title text-center mb-5">
      <div class="container">
        <div class="content-box">
          <h1>{{ $getByLang(name) }}</h1>
        </div>
      </div>
    </section>

    <div v-if="cattype == 1">
      <div class="container ">
        <div class="row mb-5" v-if="massage && massage.length > 0">
          <div
            class="col-md-4 col-sm-12 col-xs-12 col-lg-4"
            v-for="item of massage"
            :key="item.id"
          >
            <div class="ed-course-in">
              <a @click="goToSlug(item.id)" class="course-overlay">
                <img class="mimg" :src="$baseUploadURL + item.image" alt="" />
                <span>{{ $getByLang(item.name) }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(item, index) in massage"
        :key="item.id"
        :class="isEven(index) ? 'et_pb_section bg2m' : 'et_pb_section  '"
        :ref="'m_' + item.id"
      >
        <div class="et_pb_section_description">
          <h2>{{ $getByLang(item.name) }}</h2>
          <p v-html="$getByLang(item.body)"></p>
        </div>
        <div class="et_pb_section_description row">
          <div class="col-md-4 " v-for="itm of item.pricesList">
            <div class="price_box text-center mb-5">
              <h2>{{ $getByLang(itm.name) }}</h2>
              <div>
                <p>{{ itm.minutes }} Minuten</p>
                <h3>{{ itm.price }} €</h3>
              </div>

              <router-link :to="'/booking/' + item.id + '?priceId=' + itm.id"
                >{{ $t("Appointment") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container ">
        <div class="row mb-5" v-if="list && list.length > 0">
          <div
            class="col-md-4 col-sm-12 col-xs-12 col-lg-4"
            v-for="item of list"
            :key="item.id"
          >
            <div class="ed-course-in">
              <a @click="$router.push('/service/' + item.slug)" class="course-overlay">
                <img class="mimg" :src="$baseUploadURL + item.image" alt="" />
                <span>{{ $getByLang(item.name) }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      name: null,
      cattype: null,
      id: null,
      slug: null,
      massage: [],
      list: [],
    };
  },
  methods: {
    async goToSlug(id) {
      const el = await this.$refs["m_" + id][0];
      await el.scrollIntoView({ behavior: "smooth" });
    },

    isEven(n) {
      return n % 2 == 0;
    },
  },

  created() {
    const slug = this.$route.params.slug;
    this.slug = this.$route.params.slug;

    if (slug) {
      this.$http.get(`sections/getBySlug/${slug}`).then(
        (res) => {
          if (res.data) {
            this.name = res.data.data.name;

            this.cattype = res.data.data.cattype;
            this.id = res.data.data.id;

            if (this.cattype == 0) {
              this.$http.get(`sections?parntId=${this.id}`).then((res2) => {
                this.list = res2.data.data;
              });
            } else {
              let massage = res.data.data.massageList;

              for (let item of massage) {
                if (item.slug == "Kokosflockenpeeling") {
                  item.last_nom = 1;
                } else if (item.slug == "Straffungspeeling") {
                  item.last_nom = 2;
                } else if (item.slug == "wohlfühlpeeling") {
                  item.last_nom = 3;
                } else if (item.slug == "Packung mit Marokkanisch Lava Erde") {
                  item.last_nom = 4;
                } else if (item.slug == "Wohlfühlpackung") {
                  item.last_nom = 5;
                } else if (item.slug == "Straffungsölpackung") {
                  item.last_nom = 6;
                } else {
                  item.last_nom = 70;
                }
              }
              function compare(a, b) {
                if (a.last_nom < b.last_nom) {
                  return -1;
                }
                if (a.last_nom > b.last_nom) {
                  return 1;
                }
                return 0;
              }

              this.massage = massage.sort(compare);
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  },
  watch: {
    "$route.params.slug"(newv) {
      // if (old != null) {

      this.massage = [];
      this.$http.get(`sections/getBySlug/${newv}`).then(
        (res) => {
          if (res.data) {
            this.name = res.data.data.name;
            this.cattype = res.data.data.cattype;
            this.id = res.data.data.id;

            if (this.cattype == 0) {
              this.$http.get(`sections?parntId=${this.id}`).then((res2) => {
                this.list = res2.data.data;
              });
            } else {
              let massage = res.data.data.massageList;

              for (let item of massage) {
                if (item.slug == "Kokosflockenpeeling") {
                  item.last_nom = 1;
                } else if (item.slug == "Straffungspeeling") {
                  item.last_nom = 2;
                } else if (item.slug == "wohlfühlpeeling") {
                  item.last_nom = 3;
                } else if (item.slug == "Packung mit Marokkanisch Lava Erde") {
                  item.last_nom = 4;
                } else if (item.slug == "Wohlfühlpackung") {
                  item.last_nom = 5;
                } else if (item.slug == "Straffungsölpackung") {
                  item.last_nom = 6;
                } else {
                  item.last_nom = 70;
                }
              }
              function compare(a, b) {
                if (a.last_nom < b.last_nom) {
                  return -1;
                }
                if (a.last_nom > b.last_nom) {
                  return 1;
                }
                return 0;
              }

              this.massage = massage.sort(compare);
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
      // }
    },
  },
  head: {
    // title: {
    //   inner: 'Offers',
    // },
    // Meta tags
    meta: [
      { name: "application-name", content: "Kleopatra Massage & Beauty" },
      {
        name: "description",
        content: "Kleopatra Massage & Beauty",
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: "twitter:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        n: "twitter:description",
        c: "Kleopatra Massage & Beauty",
      },
      // ...
      // Google+ / Schema.org
      { itemprop: "name", content: "Kleopatra Massage & Beauty" },
      { itemprop: "description", content: "Kleopatra Massage & Beauty" },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: "og:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        p: "og:image",
        c: "https://kleopatramassage-beauty.com/image/logo.png",
      },
      // ...
    ],
  },
};
</script>
<style></style>
